
  
  function flexible(window: Window, document: Document) {
    const docEl = document.documentElement as HTMLElement;
  
    const dpr = window.devicePixelRatio || 1;
  
    // adjust body font size
    function setBodyFontSize() {
      if (document.body) {
        document.body.style.fontSize = `${(12 * dpr)}px`;
      } else {
        document.addEventListener('DOMContentLoaded', setBodyFontSize);
      }
    }
  
    setBodyFontSize();
  
    // set 1rem = viewWidth / 10
    function setRemUnitx() {
      const leftElement = document.querySelector('.left') as HTMLElement | null;
      const iframeElement = document.getElementById('my_iframe') as HTMLIFrameElement | null;
      const batteryElement = document.querySelector('.battery_box') as HTMLElement | null;
      const divElement = document.querySelector('.test') as HTMLElement | null;
  
      const rem = docEl.clientWidth / 10;
      const t = docEl.clientWidth;
      const r = docEl.clientHeight;
      const isLandscape = t < r;
  
      if (divElement) {
        if (isLandscape) {
          divElement.classList.add('block');
          divElement.classList.remove('flex_');
        } else {
          divElement.classList.add('flex_');
          divElement.classList.remove('block');
        }
      }
  
      docEl.style.fontSize = `${rem}px`;
  
      const status_height = (document.getElementById("status_box") as HTMLElement | null)?.clientHeight ?? 0;
      const r_height = (document.getElementById("right_id") as HTMLElement | null)?.clientHeight ?? 0;
  
      if (leftElement && !isLandscape) {
        leftElement.style.height = `${r_height - status_height - 20}px`;
      } else if (leftElement) {
        leftElement.style.height = `${r - r_height - 120}px`;
      }
  
      if (iframeElement && !isLandscape) {
        iframeElement.style.cssText = '';
      } else if (iframeElement) {
        iframeElement.style.height = `${r - r_height - status_height - status_height}px`;
      }
  
      if (batteryElement && !isLandscape) {
        batteryElement.style.cssText = '';
      } else if (batteryElement) {
        batteryElement.style.bottom = `${status_height + 90}px`;
      }
    }

    function setRemUnit(){
      const leftElement = document.querySelector('.left') as HTMLElement | null;
      const iframeElement = document.getElementById('my_iframe') as HTMLIFrameElement | null;
      const batteryElement = document.querySelector('.battery_box') as HTMLElement | null;
      const divElement = document.querySelector('.test') as HTMLElement | null;

      const rem = docEl.clientWidth / 10;
      docEl.style.fontSize = `${rem}px`;  // 设置根元素字体大小

      const t = docEl.clientWidth;
      const r = docEl.clientHeight;
      const isLandscape = t > r;  // 判断横竖屏
      if (isLandscape) {
        console.log('横屏');
        docEl.style.fontSize = `${rem * 0.8}px`;
      } else {
        console.log('竖屏');
        docEl.style.fontSize = `${rem}px`;
      }

    }
  
    setRemUnit();
    // reset rem unit on page resize
    window.addEventListener('resize', setRemUnit);
    window.addEventListener('pageshow', function (e: PageTransitionEvent) {
      if (e.persisted) {
        setRemUnit();
      }
    });
  
    // detect 0.5px supports
    if (dpr >= 2) {
      const fakeBody = document.createElement('body');
      const testElement = document.createElement('div');
  
      testElement.style.border = '.5px solid transparent';
      fakeBody.appendChild(testElement);
      docEl.appendChild(fakeBody);
  
      if (testElement.offsetHeight === 1) {
        docEl.classList.add('hairlines');
      }
  
      docEl.removeChild(fakeBody);
    }
  }
  
  (flexible as any)(window, document);